import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { collection, doc, getDoc, getDocs, query, where,onSnapshot } from 'firebase/firestore';
import { firestore } from '../../firebase';
import Loader from '../../loader';

const NotificationDetail = () => {
    const { uid } = useParams();
    const [notifications, setNotifications] = useState([]);
    const [savingData, setSavingData] = useState(false);
    const [language, setLanguage] = useState('English');

    useEffect(() => {
        const userDocRef = doc(firestore, 'users', uid);

        const unsubscribe = onSnapshot(userDocRef, (doc) => {
            if (doc.exists()) {
                const userData = doc.data();
                setLanguage(userData.language || 'English');
            } else {
                console.log('No such document!');
            }
        }, (error) => {
            console.error('Error getting document:', error);
        });

        // Cleanup listener on unmount
        return () => unsubscribe();
    }, [uid]);
    useEffect(() => {
        // Function to load notifications from local storage
        const loadNotificationsFromLocalStorage = () => {
            setSavingData(true);
            const savedNotifications = localStorage.getItem('notifications');
            if (savedNotifications) {
                setNotifications(JSON.parse(savedNotifications));
            }
            setSavingData(false);
        };

        // Function to fetch notifications from Firestore
   // Function to fetch notifications from Firestore
const fetchNotifications = async () => {
    setSavingData(true);
    try {
        const notificationsQuery = query(collection(firestore, 'notifications'), where('receiver', '==', uid));
        const querySnapshot = await getDocs(notificationsQuery);
        const fetchedNotifications = [];

        for (const docSnap of querySnapshot.docs) {
            const notification = docSnap.data();

            // Fetch sender's user data
            const senderDoc = await getDoc(doc(firestore, 'users', notification.sender));
            const senderUserData = senderDoc.data();

            fetchedNotifications.push({ ...notification, senderUserData });
        }

        // Sort notifications by timestamp in descending order
        fetchedNotifications.sort((a, b) => b.timestamp.toMillis() - a.timestamp.toMillis());

        setNotifications(fetchedNotifications);
        localStorage.setItem('notifications', JSON.stringify(fetchedNotifications));
        setSavingData(false);
    } catch (error) {
        console.error('Error fetching notifications:', error);
    }
};


        loadNotificationsFromLocalStorage();
        fetchNotifications();

        // Cleanup function to remove notifications from local storage when component unmounts
        return () => {
            localStorage.removeItem('notifications');
        };
    }, [uid]);


    const getTimeDifference2 = (timestamp) => {
        if (!timestamp || typeof timestamp.toDate !== 'function') {
            return '';
        }
    
        const now = new Date(); // Device's current time
        const commentTime = new Date(timestamp.toDate()); // Convert Firestore timestamp to Date object
        const timeDifference = now.getTime() - commentTime.getTime(); // Difference in milliseconds
        const secondsDifference = Math.floor(timeDifference / 1000);
        const minutesDifference = Math.floor(secondsDifference / 60);
        const hoursDifference = Math.floor(minutesDifference / 60);
        const daysDifference = Math.floor(hoursDifference / 24);
        const monthsDifference = Math.floor(daysDifference / 30); // Approximate months
        
        if (isNaN(commentTime.getTime())) {
            return '';
        } else if (secondsDifference < 60) {
            return `${secondsDifference} second${secondsDifference !== 1 ? 's' : ''} ago`;
        } else if (minutesDifference < 60) {
            return `${minutesDifference} minute${minutesDifference !== 1 ? 's' : ''} ago`;
        } else if (hoursDifference < 24) {
            return `${hoursDifference} hour${hoursDifference !== 1 ? 's' : ''} ago`;
        } else if (daysDifference < 30) {
            return `${daysDifference} day${daysDifference !== 1 ? 's' : ''} ago`;
        } else {
            return `${monthsDifference} month${monthsDifference !== 1 ? 's' : ''} ago`;
        }
    };
    return (
       <>
        {savingData && <Loader/>}
        <div className='w-full items-center flex flex-col px-[10px] lg:px-[20px] pt-[100px] pb-[70px] lg:pb-[30px] py-[30px]'>
            <div className='flex flex-col pb-[60px] pt-[50px] px-[20px] bg-white rounded-[16px] w-full lg:max-w-[500px]'>
                <div className='text-[18px] leading-[21px] text-center font-semibold text-[#000000] montserratFamily'>{language === 'ds' ? 'إشعارات' : 'Notifications'}</div>
                <div className='flex w-full flex-col'>
                    {notifications.map((item, index) => (
                        <div key={index} className='flex items-center justify-between mt-[20px]'>
                            <div className='flex items-center gap-[8px]'>
                                <img src={item.senderUserData?.imageURL} className='w-[63px] h-[63px] rounded-full' alt="" />
                                <div className='flex flex-col'>
                                    <div className='text-[13px] leading-[15px] text-[#000000] font-semibold montserratFamily'>{item.senderUserData?.username}</div>
                                    <div className='text-[10px] leading-[12px] text-[#000000] montserratFamily'>{item.text}</div>
                                    <div className='text-[10px] leading-[12px] text-[#808080] montserratFamily'>{item.content}</div>
                                </div>
                            </div>
                            <div className='text-[10px] leading-[12px] text-[#A6A6A6] font-semibold'>
                                {getTimeDifference2(item?.timestamp)}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
       </>
    );
};

export default NotificationDetail;
