import React from 'react'
import { FaChevronLeft } from "react-icons/fa";
import { BsThreeDots } from "react-icons/bs";
import { TbArrowBackUp } from "react-icons/tb";
import { Link, useLocation } from 'react-router-dom';
import { format } from 'date-fns';

const EmailDetail = ({ toggleDisplayDetail, toggleCompose }) => {
    const location = useLocation();
    const detailedEmail = location?.state?.emailDisplayed;


    const formatTime = (timestamp) => {
        // Check if timestamp is null, undefined, or not a Firestore Timestamp object
        if (!timestamp || !timestamp.toDate) {
            return "";
        }
        
        // If timestamp is a Firestore Timestamp object, convert it to JavaScript Date object
        const date = timestamp.toDate ? timestamp.toDate() : timestamp;
        
        // Format the date
        return format(date, "hh:mm a");
    };
    
    
    return (
        <>
            <div className='flex flex-col'>
                <div className='flex justify-between w-full'>
                    <div className='flex items-center gap-[5px] cursor-pointer' onClick={toggleDisplayDetail}>
                        <FaChevronLeft className='text-[15px] text-[#000000]' />
                        <div className='text-[#000000] text-[17px] font-bold leading-[20px] montserratFamily'>
                            {detailedEmail?.subject}
                        </div>
                    </div>
                    <BsThreeDots className='text-[#000000] text-[12px]' />
                </div>
                <div className='flex items-center gap-[8px] mt-[15px]'>
                    <img src={detailedEmail?.senderData?.imageURL} className='w-[45px] h-[45px] rounded-full' alt="" />
                    <div className='flex flex-col'>
                        <div className='text-[13px] leading-[15px] font-semibold text-[#000000] montserratFamily'>{detailedEmail?.senderData?.username}</div>
                        <div className='text-[10px] leading-[12px] font-semibold text-[#000000] montserratFamily'>{formatTime(detailedEmail?.timestamp)}</div>
                    </div>
                </div>
                <div className='p-[15px] tet-[17px] mt-[30px] h-fit min-h-[250px] leading-[17px] pb-[40px] text-[#000000] montserratFamily bg-[#F6F6F6] rounded-[10px]'>
                    {detailedEmail.description}
                </div>
                <Link state={{ replyItem: detailedEmail }} onClick={toggleCompose} className='linkColorLight text-[13px] montserratFamily font-medium text-[#BABABA] w-full max-w-[300px] mx-auto  h-[48px] rounded-[28px] mt-[100px] replyBtn flex items-center justify-center gap-[10px]'>
                    <TbArrowBackUp className='text-[14px] text-[#BABABA]' />
                    {location === 'Arabic' ? 'إرسال الرد' : 'Send a Reply'}
                </Link>
            </div>
        </>
    )
}

export default EmailDetail
