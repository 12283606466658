import React, { useEffect, useState } from 'react';
import { IoIosNotificationsOutline } from "react-icons/io";
import { CiSettings } from "react-icons/ci";
import { RxVideo } from "react-icons/rx";
import { MdOutlineInsertChart } from "react-icons/md";
import { CiBookmarkMinus } from "react-icons/ci";
import Button from 'react-bootstrap/Button';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { collection, doc, getDoc, getDocs, onSnapshot, query, where } from 'firebase/firestore';
import { auth, firestore } from '../../firebase';
import { useParams } from "react-router-dom";



const LeftSidebar = () => {
    const [show, setShow] = useState(false);
    const location = useLocation();
    const [userData, setUserData] = useState(null);
    const { uid } = useParams();
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const navigate = useNavigate();


    // Function to format the date
    const formatDate = (timestamp) => {
        try {
            // Convert Firestore Timestamp to JavaScript Date
            const date = timestamp.toDate();
            // Format the date
            const options = { day: 'numeric', month: 'short', year: 'numeric' };
            return date.toLocaleDateString('en-GB', options);
        } catch (error) {
            console.error('Error formatting date:', error);
            return "Invalid Date";
        }
    };
   
    useEffect(() => {
        const fetchUserData = async () => {
            try {
                // Check if user data exists in local storage
                const userDataFromStorage = localStorage.getItem('userData');
                if (userDataFromStorage) {
                    // If data exists in local storage, set it directly
                    setUserData(JSON.parse(userDataFromStorage));
                } else {
                    // If not, fetch data from Firestore
                    const userDocRef = doc(firestore, 'users', uid); // Use UID from route params
                    const unsubscribe = onSnapshot(userDocRef, (doc) => {
                        if (doc.exists()) {
                            const userData = doc.data();
                            // Update user data in state
                            setUserData(userData);
                            // Save user data to local storage if needed
                            // localStorage.setItem('userData', JSON.stringify(userData));
                        } else {
                            console.log('User document does not exist');
                        }
                    });
                    return () => unsubscribe();
                }
            } catch (error) {
                console.error('Error fetching user data:', error);
            }
        };

        fetchUserData();

        // Clear local storage on page reload
        const clearLocalStorage = () => {
            localStorage.removeItem('userData');
        };

        window.addEventListener('beforeunload', clearLocalStorage);

        return () => {
            window.removeEventListener('beforeunload', clearLocalStorage);
        };
    }, [uid]);

    const editProfile = () => {
        navigate(`/editprofile/${uid}`);
    }
    const followersNavigate = () => {
        navigate(`/followers/${uid}`);
    }
  const followingNavigate = () => {
    navigate(`/following/${uid}`);
  }
  const createPostNavigate = () => {
    navigate(`/createpost/${uid}`);
  }
  const settingsNavigate = () => {
    navigate(`/settings/${uid}`);
  }
  const notificationsNavigate = () => {
    navigate(`/notifications/${uid}`);
  }
  const myProfileNavigate = () => {
    navigate(`/myprofile/${uid}/${uid}`);
  }
  const logout = () => {
    // Remove UID from localStorage
    localStorage.removeItem('uid');
    // Navigate to the WelcomePage
    navigate('/');
};

    return (
        <>
            <button variant="primary" className="d-lg-none h-fit" onClick={handleShow}>
                <div className='w-[30px] h-[3px] bg-[black] mb-[4px]'></div>
                <div className='w-[30px] h-[3px] bg-[black] mb-[4px]'></div>
                <div className='w-[30px] h-[3px] bg-[black] mb-[4px]'></div>
            </button>
            <Offcanvas show={show} onHide={handleClose} responsive="lg" className='w-full sideOff bg-[#F6F6F6] lg:bg-none'>
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title><img src={require('../../Assets/bluelogo.png')} className='w-[70px] h-[30px]' alt="" /></Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <div className='flex flex-col items-center w-full siSection  '>
                        {userData ? (
                            <div className='flex flex-col bg-[#FFFFFF] sectionBorder w-full max-w-[356px] p-[10px] rounded-[20px] boxShadowDivs'>
                                <div className='flex justify-between items-center'>
                                    <div className='flex flex-col'>
                                        <img src={userData?.imageURL} className='w-[69px] h-[69px] rounded-full' alt="" />
                                      <button  className='flex justify-start'>
                                      <div className='montserratFamily text-[17px] text-[#000000] font-semibold mt-[5px]'>
                                            {userData?.name}
                                        </div>
                                      </button>
                                        <div className='flex items-center gap-[4px] montserratFamily text-[#B0B0B0] text-[10px] font-medium'>
                                            <img src={require('../../Assets/calenderImg.png')} className='w-[9px] h-[10px]' alt="" />
                                          Joined  {`  ${formatDate(userData.joiningDate)}`} 
                                        </div>
                                    </div>
                                    <button onClick={editProfile}>
                                        <Link className='w-[99px] h-[41px] flex items-center justify-center editBtn bg-none rounded-[28px] montserratFamily text-[10px] font-semibold text-[#000000]'> Edit Profile</Link>
                                    </button>
                                </div>
                                <div className='flex items-start justify-center gap-[8px] my-[10px]'>
                                    <div className='text-[13px] font-medium text-[#000000] leading-[15px]'>
                                        {userData.followers ? userData.followers.length : 0}{' '}
                                        <span className='text-[#B0B0B0]'>
                                           Followers
                                            </span>
                                    </div>
                                    <div className='text-[13px] font-medium text-[#000000] leading-[15px]'>
                                        {userData.following ? userData.following.length : 0}{' '}
                                        <span className='text-[#B0B0B0]'>
                                        Following
                                        </span>
                                    </div>
                                    <div className='text-[13px] font-medium text-[#000000] leading-[15px]'>{userData.posts ? userData.posts.length : 0} <span className='text-[#B0B0B0]'>
                                   Posts
                                        </span> </div>
                                </div>
                            </div>
                        ) : (
                            <div className="loader1">
                                <div className="wrapper">
                                    <div className="circle" />
                                    <div className="line-1" />
                                    <div className="line-2" />
                                    <div className="line-3" />
                                    <div className="line-4" />
                                </div>
                            </div>
                        )}
                        <div className='mt-[20px] 2xl:mt-[40px] flex flex-col bg-[#FFFFFF] sectionBorder w-full max-w-[356px] p-[20px] rounded-[20px] boxShadowDivs'>
                          <button onClick={followersNavigate}>
                          <Link  className={`mt-[20px] flex gap-[15px] items-center cursor-pointer ${location.pathname === '/followers' ? 'linkBlackblue' : 'linkBlack'}  text-[16px] leading-[18px]`}>
                                <div className='w-[30px]'>
                                    <RxVideo className={`${location.pathname === '/followers' ? 'text-[#3BA7FF]' : 'text-[#000000]'} text-[28px] `} />
                                </div>
                                Followers
                            </Link>
                          </button>
                           <button onClick={followingNavigate}>
                            <Link  className={`mt-[20px] flex gap-[15px] items-center cursor-pointer ${location.pathname === '/following' ? 'linkBlackblue' : 'linkBlack'}  text-[16px] leading-[18px]`}>
                                <div className='w-[30px]'>
                                    <MdOutlineInsertChart className={`${location.pathname === '/following' ? 'text-[#3BA7FF]' : 'text-[#000000]'} text-[23px] `} />
                                </div>
                                Following
                            </Link>
                           </button>
                          <button onClick={notificationsNavigate}>
                          <Link className={`mt-[20px] flex gap-[15px] items-center cursor-pointer  ${location.pathname === '/notifications' ? 'linkBlackblue' : 'linkBlack'} text-[16px] leading-[18px]`}>
                                <div className='w-[30px]'>
                                    <IoIosNotificationsOutline className={`${location.pathname === '/notifications' ? 'text-[#3BA7FF]' : 'text-[#000000]'} text-[25px] `} />
                                </div>
                               Notifications
                            </Link>
                          </button>
                          <button onClick={createPostNavigate}>
                          <Link  className={`mt-[20px] flex gap-[15px] items-center ${location.pathname === '/createpost' ? 'linkBlackblue' : 'linkBlack'} text-[16px] leading-[18px]`}>
                                <div className='w-[30px]'>
                                    <CiBookmarkMinus className={`text-[18px] ${location.pathname === '/createpost' ? 'text-[#3BA7FF]' : 'text-[#000000]'} ml-[4px]`} />
                                </div>
                                Posts
                            </Link>
                          </button>
                          <button onClick={settingsNavigate}>
                          <Link  className={`cursor-pointer mt-[20px] flex gap-[15px] items-center ${location.pathname === '/settings' ? 'linkBlackblue' : 'linkBlack'} text-[16px] leading-[18px]`}>
                                <div className='w-[30px]'>
                                    <CiSettings className={`${location.pathname === '/settings' ? 'text-[#3BA7FF]' : 'text-[#000000]'} text-[28px] `} />
                                </div>
                               Settings
                            </Link>
                          </button>
                        </div>
                        <button onClick={logout} className='2xl:fixed mt-[30px] bottom-[50px] w-[233px] h-[49px] bg-[#1DA1F2] border-none rounded-[25px] text-white text-[15px] montserratFamily'>Logout</button>
                    </div>
                </Offcanvas.Body>
            </Offcanvas>
        </>
    );
};

export default LeftSidebar;
