import React from 'react'
import Nav from 'react-bootstrap/Nav';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { RiHome5Fill } from "react-icons/ri";
import { MdEmail } from "react-icons/md";
import { MdAddBox } from "react-icons/md";
import { MdPerson } from "react-icons/md";
import { RiSearchLine } from "react-icons/ri";
import { useLocation } from 'react-router-dom';

const LowerNav = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { uid } = useParams();


    return (
        <>
            <div className='w-full items-center justify-center p-[10px] bg-white fixed bottom-0 lowerNav'>

                <Link to={`/home/${uid}`} className='relative w-[70px] h-[40px] justify-end flex flex-col items-center  '>
                    <RiHome5Fill className={`${location.pathname === `/home/${uid}` ? 'text-[#000000]' : 'text-[#BABABA]'} text-[24px]  `} />
                </Link>
                <Link to={`/emails/${uid}`} className='relative w-[70px] h-[40px] justify-end flex flex-col items-center  '>
                    <MdEmail className={`${location.pathname === `/emails/${uid}` ? 'text-[#000000]' : 'text-[#BABABA]'} text-[24px] `} />
                </Link>
                <Link to={`/createpost/${uid}`} className='relative w-[70px] h-[40px] justify-end flex flex-col items-center  '>
                    <MdAddBox className={`${location.pathname === `/createpost/${uid}` ? 'text-[#000000]' : 'text-[#BABABA]'} text-[24px] `} />
                </Link>
                <Link to={`/myprofile/${uid}/${uid}`} className='relative w-[70px] h-[40px] justify-end flex flex-col items-center  '>
                    <MdPerson className={`${location.pathname === `/notifications/${uid}` ? 'text-[#000000]' : 'text-[#BABABA]'} text-[24px] `} />
                </Link>
            </div>
        </>
    )
}

export default LowerNav
