import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

const LandingView = () => {
    useEffect(() => {
        const timer = setTimeout(() => {
            window.location.href = '/welcome'; // Navigate to '/welcome' after 3 seconds
        }, 3000);

        return () => clearTimeout(timer); // Clear the timer on component unmount
    }, []); // Empty dependency array ensures the effect runs only once

    return (
        <div className='flex items-center justify-center w-full h-full min-h-[100vh] bg-[#3BA7FF]'>
            <Link to='/welcome'><img src={require('../Assets/weblogo.png')} className='w-[200px] h-[100px] md:w-[290px] md:h-[127px]' alt="" /></Link>
        </div>
    );
};

export default LandingView;
