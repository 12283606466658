import React, { useState, useEffect } from 'react';
import { FaChevronLeft } from "react-icons/fa";
import { BsThreeDots } from "react-icons/bs";
import { Link, useNavigate, useParams } from 'react-router-dom';
import { arrayRemove, arrayUnion, doc, getDoc, getFirestore, onSnapshot, updateDoc } from 'firebase/firestore';
import { firestore } from '../../firebase';
import Loader from '../../loader';
import foll1 from '../../Assets/foll1.png';
import foll2 from '../../Assets/foll2.png';
import foll3 from '../../Assets/foll3.png';
import foll4 from '../../Assets/foll4.png';
import foll5 from '../../Assets/foll5.png';
import foll6 from '../../Assets/foll6.png';
import foll7 from '../../Assets/foll7.png';
import foll8 from '../../Assets/foll8.png';
import foll9 from '../../Assets/foll9.png';

const following = [
    // ... your predefined following data ...
];

const FollowSection = () => {
    const { uid } = useParams();
    const [userData, setUserData] = useState(null);
    const [followingData, setFollowingData] = useState([]);
    const [savingData, setSavingData] = useState(false);
    const navigate = useNavigate();
    const [language, setLanguage] = useState('English');

    useEffect(() => {
        const userDocRef = doc(firestore, 'users', uid);

        const unsubscribe = onSnapshot(userDocRef, (doc) => {
            if (doc.exists()) {
                const userData = doc.data();
                setLanguage(userData.language || 'English');
            } else {
                console.log('No such document!');
            }
        }, (error) => {
            console.error('Error getting document:', error);
        });

        // Cleanup listener on unmount
        return () => unsubscribe();
    }, [uid]);
    const fetchUserData = async () => {
        setSavingData(true);
        try {
            const userDocRef = doc(firestore, 'users', uid);
            const unsubscribe = onSnapshot(userDocRef, async (docSnapshot) => {
                if (docSnapshot.exists()) {
                    const userData = docSnapshot.data();
                    setUserData(userData);

                    const following = userData.following || [];
                    const followingPromises = following.map(async (followerId) => {
                        const followerDocRef = doc(firestore, 'users', followerId);
                        const followerDoc = await getDoc(followerDocRef);
                        return followerDoc.exists() ? { id: followerId, ...followerDoc.data() } : null;
                    });

                    const followingData = await Promise.all(followingPromises);
                    setFollowingData(followingData.filter(follower => follower !== null));
                } else {
                    console.log('User document does not exist');
                }
                setSavingData(false);
            });
            return () => unsubscribe();
        } catch (error) {
            console.error('Error fetching user data:', error);
            setSavingData(false);
        }
    };

    useEffect(() => {
        fetchUserData();
    }, [uid]);

    const toggleFollow = async (followerId, isFollowing) => {
        try {
            const db = getFirestore();
            const userDocRef = doc(db, 'users', followerId);
            const userDocRefCurrent = doc(db, 'users', uid);

            // Optimistically update the UI
            setFollowingData(prevFollowingData => 
                prevFollowingData.filter(follower => follower.id !== followerId)
            );

            if (isFollowing) {
                await updateDoc(userDocRef, {
                    following: arrayRemove(uid)
                });
                await updateDoc(userDocRefCurrent, {
                    following: arrayRemove(followerId)
                });
            } else {
                await updateDoc(userDocRef, {
                    following: arrayUnion(uid)
                });
                await updateDoc(userDocRefCurrent, {
                    following: arrayUnion(followerId)
                });
            }
        } catch (error) {
            console.error('Error toggling follow:', error);
            // Revert the optimistic update if there's an error
            fetchUserData();
        }
    };
    const userDetailsNavigate = (followerId) => {
        navigate(`/following/${uid}/${followerId}`);
       }
    return (
        <>
            {savingData && <Loader />}
            <div className='w-full items-center flex flex-col px-[10px] sm:px-[20px] pt-[100px] pb-[70px] lg:pb-[30px] py-[30px]'>
                <div className='flex flex-col pb-[60px] pt-[30px] md:pt-[50px] px-[15px] sm:px-[20px] bg-white rounded-[16px] w-full max-w-[500px]'>
                    <div className='flex mb-[20px] items-center gap-[5px] cursor-pointer'>
                        <Link > <FaChevronLeft className='text-[15px] text-[#000000]' /></Link>
                        <div className='text-[#000000] mx-auto text-[17px] font-bold leading-[20px] montserratFamily'>
                            {followingData.length} { (language === 'ds' ? 'المتابعين' : 'Following')}
                        </div>
                    </div>
                    {followingData.map((following) => (
                       <button onClick={() => userDetailsNavigate(following.id)}>
                        <div className='flex  items-center w-full '>
                        <Link state={{ detailUsers: following }} key={following.id} className='flex mb-[10px] items-center w-full'>
                            <img src={following.imageURL} className='w-[48px] h-[48px] rounded-full' alt="" />
                            <div className='followingInfo flex justify-between items-center'>
                                <div className='flex flex-col items-start'>
                                    <div className='text-[13px] md:text-[15px] leading-[20px] font-semibold montserratFamily text-[#000000]'>{following.username}</div>
                                    <div className='text-[8px] md:text-[10px] leading-[13px] font-medium text-[#959595] montserratFamily'>{following.followers ? following.followers.length : 0}  {language === 'ds' ? 'المتابعون' : 'Followers'} . {following.following ? following.following.length : 0}   {language === 'ds' ? 'المتابعين' : 'Following'}</div>
                                </div>
                                <button className={`${following.states === 'Requested' ? 'followingBtnnew text-[#A9A9A9]' : 'followingBtn text-[#000000]'} w-[73px] h-[25px] text-[10px] montserratFamily rounded-[29px] bg-none`} 
                                    onClick={() => {
                                        toggleFollow(following.id, following.followers && following.followers.includes(uid));
                                    }}>
                                    {following.followers && following.followers.includes(uid) ?   (language === 'ds' ? 'المتابعين' : 'Following') : 
    (language === 'ds' ? 'يتبع' : 'Follow')}
                                </button>
                            </div>
                        </Link>
                        </div>
                       </button>
                    ))}
                </div>
            </div>
        </>
    );
}

export default FollowSection;
