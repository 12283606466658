import React from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { RiHome5Fill } from "react-icons/ri";
import { MdEmail } from "react-icons/md";
import { MdAddBox } from "react-icons/md";
import { MdPerson } from "react-icons/md";
import { RiSearchLine } from "react-icons/ri";
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Offcanvas from 'react-bootstrap/Offcanvas';

const WishesNavbar = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { uid } = useParams();

    const homenavigate = () => {
        navigate(`/home/${uid}`);
    }
    const emailNavigate = () => {
        navigate(`/emails/${uid}`);
    }
    const createpostNavigate = () => {
        navigate(`/createpost/${uid}`);
    }
    const myProfileNavigate = () => {
        navigate(`/myprofile/${uid}/${uid}`);
      }

    return (
        <>
            {['sm'].map((expand) => (
                <Navbar key={expand} expand={expand} className="bg-body-tertiary navMain mb-3 w-full fixed top-0 z-1111111111 bg-white">
                    <Container fluid className='navFluid'>
                        <Navbar.Brand className='w-[30%] flex justify-center'>
                            <Link to={`/home/${uid}`}>
                                <img src={require('../../Assets/bluelogo.png')} className='w-[90px] h-[42px] sm:w-[114px] sm:h-[49px]' alt="" />
                            </Link>
                        </Navbar.Brand>
                        <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} />
                        <Navbar.Offcanvas
                            id={`offcanvasNavbar-expand-${expand}`}
                            aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
                            placement="end"
                            className='navSection'
                        >
                            <Offcanvas.Header closeButton>
                                <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                                    Offcanvas
                                </Offcanvas.Title>
                            </Offcanvas.Header>
                            <Offcanvas.Body>
                                <Nav className="navLinks justify-content-center flex-grow-1 pe-3 w-[57%] ">
                                    <button onClick={homenavigate}>
                                        <Link className='relative w-[70px] h-[50px] justify-end flex flex-col items-center'>
                                            <RiHome5Fill className={`${location.pathname === `/home/${uid}` ? 'text-[#000000]' : 'text-[#BABABA]'} text-[24px] mb-[15px]`} />
                                            <div className={`${location.pathname === `/home/${uid}` ? 'block' : 'hidden'} w-[10px] h-[10px] rounded-full bg-[#000000] mb-[-10px]`}></div>
                                        </Link>
                                    </button>
                                    <button onClick={emailNavigate}>
                                        <Link className='relative w-[70px] h-[50px] justify-end flex flex-col items-center'>
                                            <MdEmail className={`${location.pathname === `/emails/${uid}` ? 'text-[#000000]' : 'text-[#BABABA]'} text-[24px] mb-[15px]`} />
                                            <div className={`${location.pathname === `/emails/${uid}` ? 'block' : 'hidden'} w-[10px] h-[10px] rounded-full bg-[#000000] mb-[-10px]`}></div>
                                        </Link>
                                    </button>
                                    <button onClick={createpostNavigate}>
                                        <Link className='relative w-[70px] h-[50px] justify-end flex flex-col items-center'>
                                            <MdAddBox className={`${location.pathname === `/createpost/${uid}` ? 'text-[#000000]' : 'text-[#BABABA]'} text-[24px] mb-[15px]`} />
                                            <div className={`${location.pathname === `/createpost/${uid}` ? 'block' : 'hidden'} w-[10px] h-[10px] rounded-full bg-[#000000] mb-[-10px]`}></div>
                                        </Link>
                                    </button>
                                    <button onClick={myProfileNavigate}>
                                        <Link className='relative w-[70px] h-[50px] justify-end flex flex-col items-center'>
                                            <MdPerson className={`${location.pathname === `/notifications/${uid}` ? 'text-[#000000]' : 'text-[#BABABA]'} text-[24px] mb-[15px]`} />
                                            <div className={`${location.pathname === `/notifications/${uid}` ? 'block' : 'hidden'} w-[10px] h-[10px] rounded-full bg-[#000000] mb-[-10px]`}></div>
                                        </Link>
                                    </button>
                                </Nav>
                                <div className='w-[43%] flex justify-center navsearch'>
                                    <div className='bg-[#F6F6F6] rounded-[12px] flex items-center justify-between w-[347px] h-[50px] pl-[10px] pr-[20px]'>
                                        <input type="text" className='w-[247px] bgnone border-none pl-[4px] text-[13px] montserratFamily text-[#000000]' placeholder='Search here...' />
                                        <RiSearchLine className='text-[18px] text-[#3BA7FF]' />
                                    </div>
                                </div>
                            </Offcanvas.Body>
                        </Navbar.Offcanvas>
                    </Container>
                </Navbar>
            ))}
        </>
    );
}

export default WishesNavbar;
