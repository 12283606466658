import React, { useState, useEffect } from 'react';
import { FaChevronLeft } from "react-icons/fa";
import { BsThreeDots } from "react-icons/bs";
import { Link, useNavigate, useParams } from 'react-router-dom';
import { arrayRemove, arrayUnion, doc, getDoc, getFirestore, onSnapshot, updateDoc } from 'firebase/firestore';
import { firestore } from '../../firebase';
import Loader from '../../loader';

const FollowersSection = () => {
    const { uid } = useParams();
    const [userData, setUserData] = useState(null);
    const [followersData, setFollowersData] = useState([]);
    const [savingData, setSavingData] = useState(false);
    const navigate = useNavigate();
 
    const fetchUserData = async () => {
        setSavingData(true);
        try {
            const userDocRef = doc(firestore, 'users', uid);
            const unsubscribe = onSnapshot(userDocRef, async (docSnapshot) => {
                if (docSnapshot.exists()) {
                    const userData = docSnapshot.data();
                    setUserData(userData);

                    const followers = userData.followers || [];
                    const followersPromises = followers.map(async (followerId) => {
                        const followerDocRef = doc(firestore, 'users', followerId);
                        const followerDoc = await getDoc(followerDocRef);
                        return followerDoc.exists() ? { id: followerId, ...followerDoc.data() } : null;
                    });

                    const followersData = await Promise.all(followersPromises);
                    setFollowersData(followersData.filter(follower => follower !== null));
                } else {
                    console.log('User document does not exist');
                }
                setSavingData(false);
            });
            return () => unsubscribe();
        } catch (error) {
            console.error('Error fetching user data:', error);
            setSavingData(false);
        }
    };

    useEffect(() => {
        fetchUserData();
    }, [uid]);

    const toggleFollow = async (followerId, isFollowing) => {
        try {
            const db = getFirestore();
            const userDocRef = doc(db, 'users', followerId);

            // Optimistically update the UI
            setFollowersData(prevFollowers =>
                prevFollowers.map(follower =>
                    follower.id === followerId
                        ? {
                            ...follower,
                            followers: isFollowing
                                ? follower.followers.filter(followerId => followerId !== uid)
                                : [...(follower.followers || []), uid]
                        }
                        : follower
                )
            );

            if (isFollowing) {
                await updateDoc(userDocRef, {
                    followers: arrayRemove(uid)
                });
                await updateDoc(doc(db, 'users', uid), {
                    following: arrayRemove(followerId)
                });
            } else {
                await updateDoc(userDocRef, {
                    followers: arrayUnion(uid)
                });
                await updateDoc(doc(db, 'users', uid), {
                    following: arrayUnion(followerId)
                });
            }

            // fetchUserData();
        } catch (error) {
            console.error('Error toggling follow:', error);
            // Revert the optimistic update if there's an error
            fetchUserData();
        }
    };
   const userDetailsNavigate = (followerId) => {
    navigate(`/usersdetail/${uid}/${followerId}`);
   }
    return (
        <>
            {savingData && <Loader />}
            <div className='w-full items-center flex flex-col px-[10px] md:px-[20px] pt-[100px] pb-[70px] lg:pb-[30px] py-[30px]'>
                <div className='flex flex-col pb-[60px] pt-[30px] md:pt-[50px] px-[10px] md:px-[20px] bg-white rounded-[16px] w-full max-w-[500px]'>
                    <div className='flex mb-[20px] items-center gap-[5px] cursor-pointer'>
                        <Link ><FaChevronLeft className='text-[15px] text-[#000000]' /></Link>
                        <div className='text-[#000000] mx-auto text-[17px] font-bold leading-[20px] montserratFamily'>
                            {followersData.length} Followers
                        </div>
                    </div>
                    {followersData.map((follower) => (
                      <button onClick={() => userDetailsNavigate(follower.id)}>
                        <div className='flex mb-[15px] items-center w-full '>
                        <Link  state={{ deatilUsers: follower }} key={follower.id} className='flex mb-[15px] items-center w-full'>
                            <img src={follower.imageURL || ''} className='w-[55px] h-[48px] rounded-full object-cover' alt="" />
                            <div className='followingInfo flex justify-between items-center w-full'>
                                <div className='flex flex-col items-start'>
                                    <div className='text-[13px] md:text-[15px] leading-[20px] font-semibold montserratFamily text-[#000000]'>{follower.username}</div>
                                    <div className='text-[8px] md:text-[10px] leading-[13px] font-medium text-[#959595] montserratFamily'>{follower.followers ? follower.followers.length : 0}Followers. {follower.following ? follower.following.length : 0} Following</div>
                                </div>
                                <div className='flex items-center gap-[8px]'>
                                    <button className='w-[50px] md:w-[58px] h-[22px] md:h-[25px] rounded-[20px] md:rounded-[29px] border-none text-[8px] md:text-[10px] text-white font-medium montserratFamily bg-[#000000]'
                                        onClick={(e) => {
                                            e.preventDefault();
                                            toggleFollow(follower.id, follower.followers && follower.followers.includes(uid));
                                        }}>
                                        {follower.followers && follower.followers.includes(uid) ? ('Following') : ('Follow')}
                                    </button>
                                    <BsThreeDots className='text-[#000000] text-[15px]' />
                                </div>
                            </div>
                        </Link>
                        </div>
                      </button>
                    ))}
                </div>
            </div>
        </>
    );
}

export default FollowersSection;
