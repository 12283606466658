import './App.css';
import LandingView from './Pages/LandingView';
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import WelcomePage from './Pages/WelcomePage';
import Signup from './Pages/Signup';
import WishesHome from './Pages/Home';
import EmailView from './Pages/EmailView';
import WishesNotifications from './Pages/Notifications';
import CreatePost from './Pages/CreatePost';
import Following from './Pages/Following';
import Followers from './Pages/Followers';
import WishesSettings from './Pages/WishesSettings';
import FollwersDetailpage from './Pages/FollwersDetailpage';
import EditProfile from './Pages/EditProfile';
import TermAndUse from './Pages/TermAndUse';
import Privacy from './Pages/Privacy';
import ProfileDetailsPage from './Pages/UserProfile';
import MyProfileDetailPage from './Pages/MyProfile';
import FollowingDetailspage from './Pages/FollowingDetail';
import ProtectedRoute from './protected'; // Import the ProtectedRoute component

function App() {
  return (
      <Routes>
        <Route path="/" element={<LandingView />} />
        <Route path="/welcome" element={<WelcomePage />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="*" element={<WelcomePage />} /> {/* Fallback route */}
        <Route path="/" element={<WelcomePage />} />
        {/* Wrap protected routes with ProtectedRoute */}
        <Route path="/home/:uid" element={<ProtectedRoute><WishesHome /></ProtectedRoute>} />
        <Route path="/home/:uid/:postId" element={<ProtectedRoute><WishesHome /></ProtectedRoute>} />
        <Route path="/emails/:uid" element={<ProtectedRoute><EmailView /></ProtectedRoute>} />
        <Route path="/createpost/:uid" element={<ProtectedRoute><CreatePost /></ProtectedRoute>} />
        <Route path="/notifications/:uid" element={<ProtectedRoute><WishesNotifications /></ProtectedRoute>} />
        <Route path="/following/:uid" element={<ProtectedRoute><Following /></ProtectedRoute>} />
        <Route path="/followers/:uid" element={<ProtectedRoute><Followers /></ProtectedRoute>} />
        <Route path="/settings/:uid" element={<ProtectedRoute><WishesSettings /></ProtectedRoute>} />
        <Route path="/usersdetail/:uid/:userId" element={<ProtectedRoute><FollwersDetailpage /></ProtectedRoute>} />
        <Route path="/editprofile/:uid" element={<ProtectedRoute><EditProfile /></ProtectedRoute>} />
        <Route path="/termsanduse/:uid" element={<ProtectedRoute><TermAndUse /></ProtectedRoute>} />
        <Route path="/privacypolicy/:uid" element={<ProtectedRoute><Privacy /></ProtectedRoute>} />
        <Route path='/userprofile/:uid/:userId' element={<ProtectedRoute><ProfileDetailsPage /></ProtectedRoute>} />
        <Route path='/myprofile/:uid/:userId' element={<ProtectedRoute><MyProfileDetailPage /></ProtectedRoute>} />
        <Route path='/following/:uid/:userId' element={<ProtectedRoute><FollowingDetailspage /></ProtectedRoute>} />
      </Routes>
  );
}

export default App;
