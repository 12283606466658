import React, { useEffect, useState } from 'react';
import '../Styles/Styles.css';
import { FcGoogle } from "react-icons/fc";
import { FaPhoneAlt } from "react-icons/fa";
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { RecaptchaVerifier, onAuthStateChanged, signInWithPhoneNumber, signInWithPopup } from 'firebase/auth';
import { auth, firestore, googleProvider } from '../firebase';
import { doc, setDoc, getDoc } from 'firebase/firestore';

const WelcomePage = () => {
    const [isHidden, setIsHidden] = useState(false);
    const [isHidden2, setIsHidden2] = useState(false);
    const [value, setValue] = useState();
    const [phone, setPhone] = useState();
    const [inputValues, setInputValues] = useState(['', '', '', '', '', '']); 
    const navigate = useNavigate();
    const [user, setUser] = useState(null);
    const [otp,setOtp] = useState("");
    const location = useLocation();

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const postId = queryParams.get('postId');
        const storedUid = localStorage.getItem('uid');
        
        if (storedUid) {
            if (postId) {
                navigate(`/home/${storedUid}/${postId}`);
            } else {
                navigate(`/home/${storedUid}`);
            }
        }
    }, [navigate, location]);

    const handleButtonClick = () => {
        setIsHidden(true);
    };

    const handleButtonClick1 = () => {
        setIsHidden2(true);
    };

    const handleChange = (index, value) => {
        if (/^[0-9]?$/.test(value)) {
            const newInputValues = [...inputValues];
            newInputValues[index] = value;
            setInputValues(newInputValues);
            setOtp(newInputValues.join('')); 
        }
    };
    

    const signInwithGoogle = async () => {
        try {
            const result = await signInWithPopup(auth, googleProvider);
            const user = result.user;
            const userDocRef = doc(firestore, "users", user.uid);
            const userDoc = await getDoc(userDocRef);
            
            if (!userDoc.exists()) { 
                const userData = {
                   
                    email: user.email,
                  
                };
                await setDoc(userDocRef, userData);
                navigate('/signup');
            } else {
                navigate(`/home/${user.uid}`);
            }
    
            localStorage.setItem('uid', user.uid);
        } catch (error) {
            console.error("Error signing in with Google: ", error);
        }
    };
    
    const sendOTP = async () => {
        try {
            const recaptcha = new RecaptchaVerifier(auth, "recaptcha", {});
            const confirmation = await signInWithPhoneNumber(auth, phone, recaptcha);
            setUser(confirmation);
            setIsHidden2(true);
        } catch (err) {
            console.error(err);
        }
    };
    
    const verifyOTP =async () => {
          try{
          const data = await user.confirm(otp);
        
          navigate('/signup');
          console.log(data)
          }catch(err)
          {
          console.error(err)
          }
    };
    return (
        <div className='flex flex-col w-full overflow-hidden justify-center items-center relative h-[100vh]'>
            <div className={`welcomsection ${isHidden ? 'hidden' : ''}`}>
                <img src={require('../Assets/bluelogo.png')} className='w-[150px] h-[50px] md:w-[198px] md:h-[86px]' alt="" />
                <div className='montserratFamily mt-[25px] text-[#000000] font-semibold text-[16px] md:text-[20px] leading-[20px] md:leading-[24px]'>Welcome to Wishes</div>
                <div className='montserratFamily mt-[5px] md:mt-[10px] text-[11px] md:text-[13px] text-[#8B8B8B] leading-[13px]'>How would you like to signup to the app</div>
                <button onClick={signInwithGoogle}>
                    <div className='linkColor mt-[30px] montserratFamily text-[13px] text-[#000000] font-[500] continueBtn rounded-[28px] w-[300px] md:w-[357px] h-[50px] md:h-[60px] cursor-pointer flex items-center pl-[15px] gap-[50px]'>
                        <FcGoogle className='text-[25px]' />
                        Continue using Google
                    </div>
                </button>
                <button onClick={handleButtonClick} className='mt-[15px] montserratFamily text-[13px] text-[#000000] font-[500] continueBtn rounded-[28px] w-[300px] md:w-[357px] h-[50px] md:h-[60px] cursor-pointer flex items-center pl-[15px] gap-[50px]'>
                    <FaPhoneAlt className='text-[#3BA7FF] text-[25px]' />
                    Continue using Phone
                </button>
            </div>
            <div className={`welcomenew ${isHidden ? 'visible' : ''}  ${isHidden2 ? 'hidden-left' : ''}`}>
                <img src={require('../Assets/bluelogo.png')} className='w-[150px] h-[50px] md:w-[198px] md:h-[86px]' alt="" />
                <div className='montserratFamily mt-[25px] text-[#000000] font-semibold text-[20px] md:text-[25px] leading-[25px] md:leading-[30px]'>Phone Number</div>
                <PhoneInput
    className='numberSelect mt-[30px]'
    placeholder="Phone Number"
    defaultCountry="US"
    value={phone}
    onChange={(phone) => setPhone(phone)}
    countrySelectProps={{
        onChange: (country) => {
            if (country) {
                const formattedPhone = `+${phone}`;
                setPhone(formattedPhone);
            }
        }
    }}
/>

                    <div id='recaptcha'>

                    </div>
                <button onClick={sendOTP} className='w-[250px] md:w-[318px] h-[46px] rounded-[7px] border-none bg-[#3BA7FF] mt-[30px] montserratFamily text-[15px] text-white'>Send Code</button>
            </div>
            <div className={`welcomenew ${isHidden2 ? 'visible' : ''}`}>
                <img src={require('../Assets/bluelogo.png')} className='w-[150px] h-[50px] md:w-[198px] md:h-[86px]' alt="" />
                <div className='montserratFamily mt-[25px] text-[#000000] font-semibold text-[20px]  md:text-[25px] leading-[25px] md:leading-[30px]'>Verify Phone</div>
                <div className='flex items-center gap-[8px] md:gap-[15px] mt-[20px]'>
    {[0, 1, 2, 3, 4, 5].map((index) => (
        <input
            key={index}
            type="text"
            maxLength={1}
            className='codeinput'
            value={inputValues[index]}
            onChange={(e) => handleChange(index, e.target.value)}
        />
    ))}
</div>

                <div className='flex items-center justify-center gap-[4px] montserratFamily font-[500] mt-[15px] text-[13px] text-[#000000]'>
                    <img src={require('../Assets/loader.png')} className='w-[14px] h-[14px]' alt="" />
                    Resend Code
                </div>
                <button onClick={verifyOTP}>
                <Link  className='flex items-center justify-center w-[250px] md:w-[318px] h-[46px] rounded-[7px] border-none bg-[#3BA7FF] mt-[30px] montserratFamily text-[15px] text-white'>Verify Code</Link>

                </button>
            </div>
        </div>
    );
}

export default WelcomePage;
