import React from 'react'
import WishesNavbar from '../Components/Navbar/WishesNavbar'
import LeftSidebar from '../Components/leftSidebar/LeftSidebar'
import HomeForyou from '../Components/HomeForyou/HomeForyou'
import RightSidebar from '../Components/RightSidebar/RightSidebar'
import LowerNav from '../Components/Navbar/LowerNav'
import PostSection from '../Components/CreatePost/PostSection'
import FollowSection from '../Components/FollowSection/FollowSection'
import FollowersSection from '../Components/FollowSection/FollowersSection'

const Followers = () => {
    return (
        <>
            <>
                <WishesNavbar />
                <div className='flex w-full justify-center bg-[#F6F6F6] min-h-screen'>
                    <div className='leftSidebars ml-[20px] sm:ml-[30px] leftSidebar mt-[30px] lg:ml-0 lg:mt-0  fixed z-11 left-0 flex justify-start lg:justify-center px-[10px] h-full max-h-full overflow-scroll pb-[30px]'>
                        <LeftSidebar />
                    </div>
                    <div className='centerSection flex justify-center px-[10px]'>
                        <FollowersSection />
                    </div>
                    <div className='rightSidebars mr-[20px] sm:mr-[30px] rightSidebar mt-[30px] lg:mr-0 lg:mt-0  fixed z-11 right-0 flex justify-center px-[10px] h-full max-h-full overflow-scroll pb-[30px]'>
                        <RightSidebar />
                    </div>
                </div>
                <LowerNav />
            </>
        </>
    )
}

export default Followers
