import React from 'react'

const WishesPrivacy = () => {
    return (
        <>
            <div className='w-full items-center flex flex-col px-[10px] lg:px-[20px] pt-[100px] pb-[70px] py-[30px]'>
                <div className='flex flex-col pb-[60px] md:pb-[60px] pt-[30px] md:pt-[50px] px-[20px] bg-white rounded-[16px] w-full lg:max-w-[474px]'>
                    <div className='text-[19px] leading-[21px] text-center font-semibold text-[#000000] montserratFamily'>Privacy Policy</div>
                    <div className='w-full h-[1px] bg-[#EDEDED] my-[15px]'></div>
                    <div className='text-[13px] mt-[15px] leading-[15px] font-medium montserratFamily text-[#000000]'>
                        Lorem ipsum dolor sit amet consectetur. Ipsum fames diam dui vulputate mi enim netus vulputate neque. Tincidunt ipsum posuere proin mauris imperdiet at nullam. Elementum egestas leo ut purus hendrerit vestibulum ipsum urna lorem.
                    </div>
                    <ul className='pl-[20px]'>
                        <li className='text-[13px] mt-[15px] leading-[15px] font-medium montserratFamily text-[#000000]'>Arcu elementum interdum dui a semper.</li>
                        <li className='text-[13px]  leading-[15px] font-medium montserratFamily text-[#000000]'>Posuere auctor vel sapien pellentesque amet egestas.</li>
                        <li className='text-[13px]  leading-[15px] font-medium montserratFamily text-[#000000]'>Urna commodo commodo lectus nec hendrerit mauris vulputate libero vestibulum.</li>
                        <li className='text-[13px]  leading-[15px] font-medium montserratFamily text-[#000000]'>Rhoncus nec tellus pretium ullamcorper.</li>
                    </ul>
                    <div className='text-[13px] mt-[15px] leading-[15px] font-medium montserratFamily text-[#000000]'>
                        Nibh tempor vestibulum placerat et lectus.
                    </div>
                    <div className='text-[13px]  leading-[15px] font-medium montserratFamily text-[#000000]'>
                        Accumsan urna scelerisque leo id cursus enim ultrices. Donec vel pharetra molestie platea venenatis et. Sit sit amet ultricies risus risus quis magnis malesuada. Vitae cras sed enim risus. Elementum at venenatis lacus egestas imperdiet nec potenti. Accumsan ac magna cursus egestas convallis egestas nisl. Eu dis faucibus non enim et. Egestas pellentesque diam viverra gravida. Tortor viverra feugiat vitae adipiscing orci non malesuada. Quam lectus duis integer mauris tincidunt. Nec arcu nunc facilisis porttitor imperdiet massa.
                    </div>
                    <div className='text-[13px]  leading-[15px] font-medium montserratFamily text-[#000000]'>
                        Turpis habitasse vel orci elementum risus. Hac quam elit arcu pulvinar nulla nullam. Viverra eros auctor in nunc morbi fusce feugiat. Fusce rhoncus in maecenas sed. Arcu arcu tempor in mauris. Sagittis eget tincidunt ac arcu feugiat eleifend amet. Ut convallis tincidunt pharetra ut ut sed a enim. Fringilla in facilisis aenean quam consequat. Tempus iaculis netus venenatis vitae venenatis dis. Purus pharetra rutrum commodo lectus. Eu vel adipiscing ut amet volutpat id. Sit phasellus in metus elementum purus diam fermentum. Velit leo pellentesque pulvinar ac. Tristique amet euismod volutpat ut.
                    </div>
                    <div className='text-[13px]  leading-[15px] font-medium montserratFamily text-[#000000]'>
                        Turpis tellus sed faucibus sit. Nulla massa tincidunt tincidunt viverra nec. Sit id semper fringilla ultrices consectetur morbi amet placerat sit. Id ac commodo augue ullamcorper. Sed leo proin auctor nullam vehicula gravida nulla facilisi sapien. Dignissim feugiat dictum ornare in platea vel lectus. Eu augue arcu eu elementum suspendisse duis diam ullamcorper. Nisi ut feugiat.
                    </div>
                </div>
            </div>
        </>
    )
}

export default WishesPrivacy
