import React, { useEffect, useState } from 'react'
import Form from 'react-bootstrap/Form';
import { auth, firestore, storage } from '../../firebase';
import { collection, doc, getDoc, getDocs, onSnapshot, query, updateDoc, where } from 'firebase/firestore';
import { useParams } from 'react-router-dom';
import Loader from '../../loader';
import toast, { Toaster } from 'react-hot-toast';
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage';

const EditprofileComponent = () => {
    const [selectedImage, setSelectedImage] = useState(null);
    const [selectedImage2, setSelectedImage2] = useState(null);
    const [userData, setUserData] = useState(null);
    const [showGenderField, setShowGenderField] = useState(true);
    const {uid} = useParams();
    const [savingData, setSavingData] = useState(false);
    const [language, setLanguage] = useState('English');

    useEffect(() => {
        const userDocRef = doc(firestore, 'users', uid);

        const unsubscribe = onSnapshot(userDocRef, (doc) => {
            if (doc.exists()) {
                const userData = doc.data();
                setLanguage(userData.language || 'English');
            } else {
                console.log('No such document!');
            }
        }, (error) => {
            console.error('Error getting document:', error);
        });

        return () => unsubscribe();
    }, [uid]);

    useEffect(() => {
        const fetchUserData = async () => {
            setSavingData(true);
            try {
                const userDocRef = doc(firestore, 'users', uid); 
                const userDocSnap = await getDoc(userDocRef);
                if (userDocSnap.exists()) {
                    setUserData(userDocSnap.data());
                    setSavingData(false);
                } else {
                    console.log('User document does not exist');
                    setSavingData(false);
                }
            } catch (error) {
                console.error('Error fetching user data:', error);
                setSavingData(false);
            }
        };
    
        fetchUserData();
    }, [uid]);
    useEffect(() => {
        if (userData && userData.accountType === 'Company') {
            setShowGenderField(false);
        } else {
            setShowGenderField(true);
        }
    }, [userData]);

    const handleImageChange = (event) => {
        const file = event.target.files[0];
        setSelectedImage2(file);

        if (file) {
            const reader = new FileReader();

            reader.onload = () => {
                setSelectedImage(reader.result);
            };

            reader.readAsDataURL(file);
        }
    };

    const handleNameChange = (event) => {
        setUserData({ ...userData, name: event.target.value });
    };
    const handleUserNameChange = (event) => {
        setUserData({ ...userData, username: event.target.value });
    };
    const handleEmailChange = (event) => {
        setUserData({ ...userData, email: event.target.value });
    };

    const handleBioChange = (event) => {
        setUserData({ ...userData, bio: event.target.value });
    };

    const handleSave = async () => {
        setSavingData(true);
        try {
            // Fetch the original user data
            const userDocRef = doc(firestore, 'users', uid);
            const userDocSnap = await getDoc(userDocRef);
            if (!userDocSnap.exists()) {
                toast.error('User document does not exist');
                setSavingData(false);
                return;
            }
            const originalUserData = userDocSnap.data();
    
            // Check if the new name already exists in the users collection if it's changed
            if (userData.name !== originalUserData.name) {
                const nameQuery = query(collection(firestore, 'users'), where('name', '==', userData.name));
                const nameQuerySnapshot = await getDocs(nameQuery);
                if (!nameQuerySnapshot.empty) {
                    toast.error('Name already exists. Try a different one.');
                    setSavingData(false);
                    return;
                }
            }
    
            // Check if the new username already exists in the users collection if it's changed
            if (userData.username !== originalUserData.username) {
                const usernameQuery = query(collection(firestore, 'users'), where('username', '==', userData.username));
                const usernameQuerySnapshot = await getDocs(usernameQuery);
                if (!usernameQuerySnapshot.empty) {
                    toast.error('Username already exists. Try a different one.');
                    setSavingData(false);
                    return;
                }
            }
    
            let imageURL = userData.imageURL; // Default to existing image URL
            if (selectedImage2) {
                // If a new image is selected, upload it and get the URL
                const storageRef = ref(storage, `images/${uid}`);
                const snapshot = await uploadBytes(storageRef, selectedImage2);
                imageURL = await getDownloadURL(snapshot.ref);
            }
    
            // Update userData with the new imageURL
            const userDataToUpdate = { ...userData, imageURL };
    
            // Update user document
            await updateDoc(userDocRef, userDataToUpdate);
    
            console.log('User data updated successfully');
            setSavingData(false);
            toast.success('Profile updated successfully');
        } catch (error) {
            console.error('Error updating user data:', error);
            setSavingData(false);
            toast.error('Error updating data');
        }
    };
    
    return (
        <>
        <Toaster position='top-center'/>
         {savingData && <Loader/>}

            <div className='w-full items-center flex flex-col px-[10px] lg:px-[20px] pb-[70px] lg:pb-[30px] pt-[100px] py-[30px]'>
                <div className='flex flex-col pb-[60px] md:pb-[30px] pt-[30px] md:pt-[50px] px-[20px] bg-white rounded-[16px] w-full lg:max-w-[474px]'>
                    <div className='text-[19px] leading-[21px] text-center font-semibold text-[#000000] montserratFamily'>{language === 'ds' ? 'تعديل الملف الشخصي' : 'Edit Profile'}</div>
                    <div className='w-full h-[1px] bg-[#EDEDED] mt-[15px]'></div>
                    <div className='flex flex-col items-center mt-[20px] gap-[10px]'>
                        {selectedImage ? (
                            <img src={selectedImage} className='w-[92px] h-[92px] rounded-full' alt="" />
                        ) : (
                            <img src={userData?.imageURL} className='w-[92px] h-[92px] rounded-full' alt="" />
                        )}
                        <label className='text-[13px] leading-[15px] font-semibold text-[#3BA7FF] montserratFamily'>
                            <input type="file" accept="image/*" onChange={handleImageChange} />
                            Change Photo
                        </label> 
                    </div>
                    <div className='mx-auto mt-[15px] flex flex-col w-[300px] md:w-[328px] '>
                        <div className='text-[10px] pl-[10px] leading-[12px] text-[#000000] font-semibold montserratFamily'>{language === 'ds' ? 'اسمك' : 'Your Name'}</div>
                        <input dir={language === 'ds' ? 'rtl' : 'ltr'} type="text" className='w-full h-[41px] md:h-[51px] editnameInput rounded-[28px] border-none mt-[8px] pl-[15px] bg-[#F6F6F6] text-[13px] md:text-[15px] leading-[18px] text-[#000000] font-semibold montserratFamily' placeholder={userData && userData.accountType === 'Company' ? userData.companyName : userData && userData.name}
                          value={ userData?.name || ''} onChange={handleNameChange} />
                    </div>
                    <div className='mx-auto mt-[15px] flex flex-col w-[300px] md:w-[328px] '>
                        <div className='text-[10px] pl-[10px] leading-[12px] text-[#000000] font-semibold montserratFamily'>{language === 'ds' ? 'اسم المستخدم' : 'User Name'}</div>
                        <input dir={language === 'ds' ? 'rtl' : 'ltr'} type="text" className='w-full h-[41px] md:h-[51px] editnameInput rounded-[28px] border-none mt-[8px] pl-[15px] bg-[#F6F6F6] text-[13px] md:text-[15px] leading-[18px] text-[#000000] font-semibold montserratFamily' placeholder={ userData?.username}
                          value={ userData?.username || ''} readOnly />
                    </div>
                    <div className='mx-auto mt-[15px] flex flex-col w-[300px] md:w-[328px]  '>
                        <div className='text-[10px] pl-[10px] leading-[12px] text-[#000000] font-semibold montserratFamily'>{language === 'ds' ? 'بريد إلكتروني' : 'Email'}</div>
                        <input dir={language === 'ds' ? 'rtl' : 'ltr'} type="email " className='w-full h-[51px] editnameInput rounded-[28px] border-none mt-[8px] pl-[15px] bg-[#F6F6F6] text-[13px] md:text-[15px] leading-[18px] text-[#000000] font-semibold montserratFamily' placeholder={userData?.email}
                          value={userData?.email || ''} onChange={handleEmailChange} />
                    </div>
                    {showGenderField && userData && userData.accountType === 'personal' && (
                <div className='mx-auto mt-[15px] flex flex-col w-[300px] md:w-[328px]  '>
                    <div className='text-[10px] pl-[10px] leading-[12px] text-[#000000] font-semibold montserratFamily'>Gender</div>
                    <Form.Select aria-label="Default select example" className='genderSelect'>
                        <option value="1">Female</option>
                        <option value="2">Male</option>
                    </Form.Select>
                </div>
            )}
            <div className='mx-auto mt-[15px] flex flex-col w-[300px] md:w-[328px]  '>
                <div className='text-[10px] pl-[10px] leading-[12px] text-[#000000] font-semibold montserratFamily'>
                    {userData && userData.accountType === 'Company' ? (language === 'ds' ? 'تاريخ التشكيل' : 'Formation Date') : (language === 'ds' ? 'تاريخ الميلاد' : 'Date of Birth')}
                </div>
                <input 
                    type="date"
                    dir={language === 'ds' ? 'rtl' : 'ltr'}
                    className='w-full h-[51px] editnameInput rounded-[28px] border-none mt-[8px] pl-[15px] bg-[#F6F6F6] text-[13px] md:text-[15px]] leading-[18px] text-[#000000] font-semibold montserratFamily' 
                    value={userData && userData.accountType === 'Company' ? userData.formationDate : (userData ? userData.dob : '')} 
                    onChange={(e) => setUserData({...userData, dob: e.target.value})} 
                />    
            </div>
                    <div className='mx-auto mt-[15px] flex flex-col w-[300px] md:w-[328px]  '>
                        <div className='text-[10px] pl-[10px] leading-[12px] text-[#000000] font-semibold montserratFamily'>{language === 'ds' ? 'السيرة الذاتية' : 'Bio'}</div>
                        <textarea dir={language === 'ds' ? 'rtl' : 'ltr'} type="date" className='w-full h-[100px] md:h-[130px] editBio rounded-[28px] border-none mt-[8px] p-[15px] bg-[#F6F6F6] text-[13px] md:text-[15px] leading-[18px] text-[#000000] font-semibold montserratFamily' placeholder='Enter here'
                        value={userData?.bio || ''} onChange={handleBioChange} ></textarea>
                    </div>
                    <button onClick={handleSave} className='w-[300px] md:w-[328px]  h-[51px] mx-auto rounded-[28px] border-none mt-[15px] montserratFamily bg-[#3BA7FF] text-[15px] font-semibold text-white'>Save</button>
                </div>
            </div>
        </>
    )
}

export default EditprofileComponent
