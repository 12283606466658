import React, { useEffect, useState } from 'react'
import { FaChevronLeft } from "react-icons/fa";
import { BsThreeDots } from "react-icons/bs";
import { GoHeart } from "react-icons/go";
import { FaRegCommentDots } from "react-icons/fa";
import { HiOutlineShare } from "react-icons/hi";
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { addDoc, arrayUnion, collection, doc, getDoc, getDocs, onSnapshot, query, updateDoc, where } from 'firebase/firestore';
import { firestore } from '../../firebase';
import Loader from '../../loader';
import Modal from 'react-bootstrap/Modal';

const FollowingDetails = () => {
    const location = useLocation();
    const {userId} = useParams();
    const [followingData, setFollowingData] = useState([]);
    const [savingData, setSavingData] = useState(false);
    const [userData, setUserData] = useState(null);
    const [posts, setPosts] = useState([]);
    const {uid} = useParams();
    const navigate = useNavigate();
    const [showModal, setShowModal] = useState(false); // State to control modal visibility
    const [selectedPostId, setSelectedPostId] = useState(null);
    const [comments, setComments] = useState([]); // State to store comments
    const [loading, setLoading] = useState(false); // State to track loading
    const [expandedIndex, setExpandedIndex] = useState(null);
    const [modalPost, setModalPost] = useState(null); // State to store post data for modal

    const user = location?.state?.deatilUsers
    const [language, setLanguage] = useState('English');

    useEffect(() => {
        const userDocRef = doc(firestore, 'users', uid);

        const unsubscribe = onSnapshot(userDocRef, (doc) => {
            if (doc.exists()) {
                const userData = doc.data();
                setLanguage(userData.language || 'English');
            } else {
                console.log('No such document!');
            }
        }, (error) => {
            console.error('Error getting document:', error);
        });

        // Cleanup listener on unmount
        return () => unsubscribe();
    }, [uid]);
    const fetchUserData = async () => {
        setSavingData(true);
        try {
            const userDocRef = doc(firestore, 'users', userId);
            const unsubscribe = onSnapshot(userDocRef, async (docSnapshot) => {
                if (docSnapshot.exists()) {
                    const userData = docSnapshot.data();
                    setUserData(userData);

                    const following = userData.following || [];
                    const followingPromises = following.map(async (followerId) => {
                        const followerDocRef = doc(firestore, 'users', followerId);
                        const followerDoc = await getDoc(followerDocRef);
                        return followerDoc.exists() ? { id: followerId, ...followerDoc.data() } : null;
                    });

                    const followingData = await Promise.all(followingPromises);
                    setFollowingData(followingData.filter(follower => follower !== null));
                } else {
                    console.log('User document does not exist');
                }
                setSavingData(false);
            });
            return () => unsubscribe();
        } catch (error) {
            console.error('Error fetching user data:', error);
            setSavingData(false);
        }
    };

    useEffect(() => {
        fetchUserData();
    }, [userId]);
    useEffect(() => {
        const fetchPosts = async () => {
            setSavingData(true);
            try {
                const postsCollectionRef = collection(firestore, 'posts');
                const q = query(postsCollectionRef, where('uid', '==', userId));
                console.log(userId);
                const unsubscribe = onSnapshot(q, (querySnapshot) => {
                    const postsData = [];
                    querySnapshot.forEach((doc) => {
                        const postData = doc.data();
                        postsData.push({ id: doc.id, ...postData });
                    });
                    
                    // Sort postsData array by timestamp in descending order
                    postsData.sort((a, b) => b.timestamp - a.timestamp);
                    
                    setPosts(postsData);
                    setSavingData(false);
                    console.log(q);
                });
                return () => unsubscribe();
            } catch (error) {
                console.error('Error fetching posts:', error);
            }
        };
    
        fetchPosts();
    }, [userId]);
    
    const handleCloseModal = () => {
        setShowModal(false);
        setComments([]); 
    };
    const formatDate = (timestamp) => {
        try {
            // Convert Firestore Timestamp to JavaScript Date
            const date = timestamp.toDate();
            // Format the date
            const options = { day: 'numeric', month: 'short', year: 'numeric' };
            return date.toLocaleDateString('en-GB', options);
        } catch (error) {
            console.error('Error formatting date:', error);
            return "Invalid Date";
        }
    }; 
    const getTimeDifference = (timestamp) => {
        const now = new Date(); // Device's current time
        const commentTime = new Date(timestamp.toDate()); // Convert Firestore timestamp to Date object
        const timeDifference = now.getTime() - commentTime.getTime(); // Difference in milliseconds
        const secondsDifference = Math.floor(timeDifference / 1000);
        const minutesDifference = Math.floor(secondsDifference / 60);
        const hoursDifference = Math.floor(minutesDifference / 60);
        const daysDifference = Math.floor(hoursDifference / 24);
        const monthsDifference = Math.floor(daysDifference / 30); // Approximate months
        
        if (isNaN(commentTime.getTime())) {
            return 'Invalid date';
        } else if (secondsDifference < 60) {
            return `${secondsDifference} second${secondsDifference !== 1 ? 's' : ''} ago`;
        } else if (minutesDifference < 60) {
            return `${minutesDifference} minute${minutesDifference !== 1 ? 's' : ''} ago`;
        } else if (hoursDifference < 24) {
            return `${hoursDifference} hour${hoursDifference !== 1 ? 's' : ''} ago`;
        } else if (daysDifference < 30) {
            return `${daysDifference} day${daysDifference !== 1 ? 's' : ''} ago`;
        } else {
            return `${monthsDifference} month${monthsDifference !== 1 ? 's' : ''} ago`;
        }
    };
    const handleLike = async (postId, index) => {
        try {
            const updatedPosts = [...posts];
            const currentPost = updatedPosts[index];
    
            const alreadyLiked = currentPost.likes && currentPost.likes.includes(uid);
    
            if (alreadyLiked) {
                currentPost.likes = currentPost.likes.filter(id => id !== uid);
            } else {
                currentPost.likes = (currentPost.likes || []).concat(uid);
            }
    
            currentPost.liked = !alreadyLiked;
    
            updatedPosts[index] = currentPost;
            setPosts(updatedPosts);
    
            const postRef = doc(firestore, 'posts', postId);
            await updateDoc(postRef, {
                likes: currentPost.likes
            });
    
            // Add notification for like
            await addNotification(uid, currentPost.uid, alreadyLiked ? 'unliked your post' : 'liked your post');
    
        } catch (error) {
            console.error('Error toggling like:', error);
        }
    };
    
    const addNotification = async (sender, receiver, text) => {
        try {
            const notificationData = {
                sender,
                receiver,
                text,
                timestamp: new Date(),
            };
            await addDoc(collection(firestore, 'notifications'), notificationData);
        } catch (error) {
            console.error('Error adding notification:', error);
        }
    };
    const navigateback = () => {
        navigate(`/following/${uid}`);
    }
    const openModal = async (postId) => {
        try {
            setSavingData(true);
            setShowModal(true);
            setSelectedPostId(postId);
            const postRef = doc(firestore, 'posts', postId);
            const postDoc = await getDoc(postRef);
            const post = postDoc.data();
            const userDoc = await getDoc(doc(firestore, 'users', post.uid));
            const userData = userDoc.data();
            setModalPost({ id: postId, ...post, userData });
    
            // Fetch comments along with user data
            const commentsQuery = query(collection(firestore, 'comments'), where('postId', '==', postId));
            const commentsSnapshot = await getDocs(commentsQuery);
            const fetchedComments = [];
    
            for (const commentDoc of commentsSnapshot.docs) {
                const comment = commentDoc.data();
                const userDoc = await getDoc(doc(firestore, 'users', comment.senderUid));
                const userData = userDoc.data();
                fetchedComments.push({ ...comment, userData });
            }
    
            setComments(fetchedComments);
            setShowModal(true);
            setSavingData(false);
        } catch (error) {
            console.error('Error opening modal:', error);
        }
    };
    const handleSendComment = async (postId) => {
        try {
            const commentInput = document.querySelector('.commentInput');
            const commentText = commentInput.value.trim();
            
            if (commentText) {
                await addComment(postId, commentText);
                    commentInput.value = '';
            }
        } catch (error) {
            console.error('Error sending comment:', error);
        }
    };
    const addComment = async (postId, commentText) => {
        try {
            const postDataItem = posts.find(post => post.id === postId);
            if (!postDataItem) {
                console.error('Post data not found for postId:', postId);
                return;
            }
            const commentData = {
                postId,
                commentDescription: commentText,
                senderUid: uid,
                recieverUid: postDataItem.uid,
                timestamp: new Date(),
                commentImageURL:[],
                likes:[],
                parentId:"",
            };
            const commentRef = await addDoc(collection(firestore, 'comments'), commentData);
            await updateDoc(commentRef, { commentId: commentRef.id });
            const postRef = doc(firestore, 'posts', postId);
            await updateDoc(postRef, {
                comment: arrayUnion(commentRef.id),
            });
            setPosts(prevData => {
                return prevData.map(post => {
                    if (post.id === postId) {
                        return {
                            ...post,
                            comment: [
                                ...(post.comment || []),
                                {
                                    id: commentRef.id,
                                    text: commentText,
                                    recieverUid: postDataItem.uid,
                                },
                            ],
                        };
                    }
                    return post;
                });
            });

            // Add notification for comment
            await addNotification(uid, postDataItem.uid, `Comment on your post: ${commentText}`);
        } catch (error) {
            console.error('Error adding comment:', error);
        }
    };
    const getTimeDifference2 = (timestamp) => {
        if (!timestamp || typeof timestamp.toDate !== 'function') {
            return '';
        }
    
        const now = new Date(); // Device's current time
        const commentTime = new Date(timestamp.toDate()); // Convert Firestore timestamp to Date object
        const timeDifference = now.getTime() - commentTime.getTime(); // Difference in milliseconds
        const secondsDifference = Math.floor(timeDifference / 1000);
        const minutesDifference = Math.floor(secondsDifference / 60);
        const hoursDifference = Math.floor(minutesDifference / 60);
        const daysDifference = Math.floor(hoursDifference / 24);
        const monthsDifference = Math.floor(daysDifference / 30); // Approximate months
        
        if (isNaN(commentTime.getTime())) {
            return '';
        } else if (secondsDifference < 60) {
            return `${secondsDifference} second${secondsDifference !== 1 ? 's' : ''} ago`;
        } else if (minutesDifference < 60) {
            return `${minutesDifference} minute${minutesDifference !== 1 ? 's' : ''} ago`;
        } else if (hoursDifference < 24) {
            return `${hoursDifference} hour${hoursDifference !== 1 ? 's' : ''} ago`;
        } else if (daysDifference < 30) {
            return `${daysDifference} day${daysDifference !== 1 ? 's' : ''} ago`;
        } else {
            return `${monthsDifference} month${monthsDifference !== 1 ? 's' : ''} ago`;
        }
    };
    return (
        <>
               {savingData && <Loader/>}

            <div className='w-full items-center flex flex-col px-[10px] md:px-[20px] pt-[100px] pb-[70px] lg:pb-[30px] py-[30px]'>
                <div className='flex flex-col items-center pb-[60px] pt-[30px] md:pt-[50px] px-[20px] bg-white rounded-[16px] w-full max-w-[453px]'>
                    <div className='flex flex-col  w-full max-w-[348px]'>
                        <div className='flex mb-[10px] md:mb-[20px] items-center gap-[5px] cursor-pointer' >
                        <button onClick={navigateback}>
                        <Link > <FaChevronLeft className='text-[15px] text-[#000000]' /></Link>

                        </button> 
                            <div className='text-[#000000] mx-auto text-[19px] font-semibold leading-[23px] montserratFamily'>
                                {userData?.username}
                            </div>
                        </div>
                        <div className='w-full h-[1px] bg-[#EDEDED] my-[10px] md:my-[15px]'></div>
                        <div className='flex items-center justify-between'>
                            <img src={userData?.imageURL} className='w-[84px] h-[84px] rounded-full' alt="" />
                            {user && user.states ? (
                                <div></div>
                            ) : (
                                <button className='w-[101px] h-[35px] rounded-[28px] text-[12px] font-semibold text-[#3BA7FF] montserratFamily bg-none followersBtn'>
                                                                           {userData?.followers && userData?.followers.includes(uid) ? (language === 'ds' ? 'المتابعين' : 'Following') : (language === 'ds' ? 'يتبع' : 'Follow')}

                                </button>
                            )}
                        </div>
                        <div className='mt-[20px] flex flex-col'>
                            <div className='text-[17px] leading-[20px] text-[#000000] font-semibold montserratFamily'>{user?.username}</div>
                            <div className='mt-[4px] flex items-center gap-[4px] montserratFamily text-[#B0B0B0] text-[10px] font-medium'>
                                <img src={require('../../Assets/calenderImg.png')} className='w-[9px] h-[10px]' alt="" />
                                {language === 'ds' ? '' : 'Joined'}     {` ${formatDate(userData?.joiningDate)}`}{language === 'ds' ? 'انضم' : ''}
                            </div>
                            <div className='text-[10px] text-[#000000] font-medium pt-2'>
                                {userData?.bio ? userData?.bio : ''}
                            </div>

                            <div className='flex items-start gap-[8px] my-[10px]'>
                                <div className='text-[13px] font-medium text-[#000000] leading-[15px]'>{userData?.followers ? userData?.followers.length : 0} <span className='text-[#B0B0B0]'>{language === 'ds' ? 'المتابعون' : 'Followers'}</span> </div>
                                <div className='text-[13px] font-medium text-[#000000] leading-[15px]'>{userData?.following ? userData?.following.length : 0} <span className='text-[#B0B0B0]'>{language === 'ds' ? 'المتابعين' : 'Following'}</span> </div>
                                <div className='text-[13px] font-medium text-[#000000] leading-[15px]'>{posts.length} <span className='text-[#B0B0B0]'> {language === 'ds' ? 'المنشورات' : 'Posts'}</span> </div>
                            </div>
                         
                        </div>
                        <div className='w-full h-[1px] bg-[#EDEDED] my-[25px]'></div>
                        {posts.map((post, index) => (
                        <div className='flex w-full flex-col my-[10px]' key={post.id}>
                            <div className='flex items-center justify-between'>
                                <div className='flex items-center gap-[4px]'>
                                    <img src={userData?.imageURL} className='w-[44px] h-[44px] rounded-full' alt="" />
                                    <div className='flex flex-col'>
                                        <div className='text-[13px] leading-[16px] font-semibold text-[#000000] montserratFamily'>{userData?.username}</div>
                                        <div className='text-[10px] leading-[12px] text-[#B0B0B0] montserratFamily'>{getTimeDifference(post?.timestamp)}</div>
                                    </div>
                                </div>
                                <BsThreeDots className='text-[15px] text-[#CACACA]' />
                            </div>
                           <div className={`mt-[12px] max-w-[490px] font-medium text-[13px] montserratFamily text-[#000000] leading-[18px] ${post.language === 'ds' ? 'text-right' : ''}`}>
                    <span className='christfamily text-[13px] leading-[13px] text-[#3BA7FF] mr-[3px]'>
                        {post.language === 'ds' ? '  أتمنى' : 'I wish'}
                    </span>
                    {post.description}
                </div>
                            {post.postImageURL && post.postImageURL.length > 0 ? (
            <div className='flex item-center justify-center mt-[10px] gap-[8px] cursor-pointer'  onDoubleClick={() => handleLike(post.id, index)}>
                {/* If only one image, display it alone */}
                {post.postImageURL.length === 1 ? (
                    <img
                        src={post.postImageURL[0]} 
                        className='w-[200px] md:w-[244px] h-[170px] md:h-[226px] rounded-[10px]' 
                        alt=""
                    />
                ) : (
                    // If multiple images, display them in a grid layout
                    <div className='flex flex-col gap-[8px]'>
                        {post.postImageURL.map((imageUrl, index) => (
                            <div className='flex item-center justify-center mt-[10px] gap-[8px]' key={index}>
                                {index === 0 && (
                                    <img src={imageUrl} className='w-[200px] md:w-[244px] h-[170px] md:h-[226px] rounded-[10px]' alt="" />
                                )}
                                {index === 0 && (
                                    <div className='flex flex-col gap-[8px]'>
                                        <img src={post.postImageURL[1]} className='md:w-[96px] w-[80px] h-[80px] md:h-[109px] rounded-[10px]' alt="" />
                                        <img src={post.postImageURL[2]} className='md:w-[96px] w-[80px] h-[80px] md:h-[109px] rounded-[10px]' alt="" />
                                    </div>
                                )}
                            </div>
                        ))}
                    </div>
                )}
            </div>
        ) : null /* If postImageURL is not found or empty, don't render anything */}
                    
                            <div className='flex justify-between items-center mt-[15px]'>
                                <div className='flex items-center gap-[5px]'>
                                <GoHeart
    className={`${post.likes && post.likes.includes(uid) ? 'text-[#FF002E]' : 'text-[#A2A2A2]'} text-[20px]`}
    onClick={() => handleLike(post.id, index)}
/>
                                    <div className='text-[8px] text-[#000000] leading-[10px]'>{post.likes ? post.likes.length: 0} {language === 'ds' ? 'الإعجابات' : 'Likes'}</div>
                                </div>
                                <div className='flex items-center gap-[5px]'>
                                    <FaRegCommentDots className='text-[#A2A2A2] text-[20px] cursor-pointer' onClick={() => openModal(post.id)} />
                                    <div className='text-[8px] text-[#A2A2A2] leading-[10px] cursor-pointer' onClick={() => openModal(post.id)}>{post.comment ? post.comment.length : 0} {language === 'ds' ? 'تعليقات' :'Comments'}</div>
                                </div>
                                <div className='flex items-center gap-[5px]'>
                                    <HiOutlineShare className='text-[#A2A2A2] text-[20px]' />
                                    <div className='text-[8px] text-[#A2A2A2] leading-[10px]'>234 {language === 'ds' ? 'تشارك' : 'Shares'}</div>
                                </div>
                            </div>
                            {expandedIndex === index && (
    <div className='flex items-center justify-between w-full py-[20px] commentSection'>
        <div className='flex gap-[10px] items-center'>
            <img src={userData?.imageURL} className='w-[43px] h-[43px] rounded-full' alt="" />
            <input type="text" className='commentInput' placeholder='Type here' />
        </div>
        <button className='sendBtn' onClick={() => handleSendComment(post.id)}>Send</button>
    </div>
)}
                        </div>
                        ))}
                       
                      
                    </div>
                </div>
                
            </div >
           
            <Modal show={showModal} onHide={handleCloseModal} centered>
            <Modal.Header closeButton>
                <Modal.Title>{language === 'ds' ? 'تعليقات' : 'Comments'}</Modal.Title>
            </Modal.Header>
            <div className="modal-body-container">
                <Modal.Body className="modal-body-scroll">
                    {modalPost && (
                        <>
                            <div className='flex items-center gap-[10px] '>
                                <img src={modalPost.userData?.imageURL || "https://cdn-icons-png.flaticon.com/128/4140/4140077.png"} className='w-[61px] h-[53px] rounded-[50%] object-cover' alt="" />
                                <div className='flex flex-col'>
                                    <div className='text-[13px] leading-[15px] font-semibold montserratFamily'>{modalPost.userData?.username}</div>
                                    <div className='text-[10px] leading-[12px] text-[#B0B0B0] font-semibold montserratFamily'>{getTimeDifference2(modalPost.timestamp)}</div>
                                </div>
                            </div>
                            <div className='mt-[12px] max-w-[490px] font-medium text-[13px] montserratFamily text-[#000000] leading-[18px]'>
                                {modalPost.description}
                            </div>
                            {modalPost?.postImageURL && modalPost?.postImageURL.length > 0 && (
                                <img src={modalPost?.postImageURL[0]} className='mt-[12px] w-full h-[180px] sm:h-[250px] rounded-[10px] object-contain cursor-pointer' alt="" />
                            )}
                            <div>
                                {comments.map((comment, index) => (
                                    <div key={index} className='mt-[12px] flex gap-[10px] '>
                                        <img src={comment.userData?.imageURL || "https://cdn-icons-png.flaticon.com/128/4140/4140077.png"} className='w-[43px] h-[43px] rounded-full' alt="" />
                                        <div className='flex flex-col'>
                                            <div className='text-[12px] leading-[14px] font-semibold montserratFamily'>{comment.userData?.username}</div>
                                            <div className='text-[11px] leading-[13px] text-[#000000] montserratFamily'>{comment.commentDescription}</div>
                                            <div className='text-[10px] leading-[12px] text-[#B0B0B0] font-semibold montserratFamily'>{getTimeDifference2(comment.timestamp)}</div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </>
                    )}
                </Modal.Body>
                <div className='modal-footer'>
                    <div className='flex items-center justify-between w-full py-[20px]'>
                        <div className='flex gap-[10px] items-center'>
                            <img src={userData?.imageURL || "https://cdn-icons-png.flaticon.com/128/4140/4140077.png"} className='w-[43px] h-[43px] rounded-full' alt="" />
                            <input type="text" className='commentInput' placeholder={language === 'ds' ? 'أكتب هنا' : 'Type here'} />
                        </div>
                        <button className='sendBtn' onClick={handleSendComment}>{language === 'ds' ? 'يرسل' : 'Send'}</button>
                    </div>
                </div>
            </div>
            <style jsx>{`
                .modal-body-container {
                    display: flex;
                    flex-direction: column;
                    max-height: 70vh;
                }
                .modal-body-scroll {
                    flex: 1;
                    overflow-y: auto;
                }
                .modal-footer {
                    border-top: 1px solid #ccc;
                    padding: 10px 20px;
                    background-color: white;
                }
            `}</style>
        </Modal>
        </>
    )
}

export default FollowingDetails
